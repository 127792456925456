import React from 'react';
import BuyTicket from '../components/BuyTicket';
const BuyTicketPage = () => {
  return (
    <div>
      <BuyTicket />
    </div>
  )
}

export default BuyTicketPage;