import {Box, Flex, Text, Divider, Center} from "@chakra-ui/react";
import {colors} from "../Theme";
import {useEffect, useState} from 'react'
import axios from "../api/axios";
import { TicketGoal } from "../utils/constants/constants";

const FirstRound =()=>{
    const [totalTickets, setTotalTickets] = useState(0);
    useEffect(() => {
        axios.get("/winningtickets/getallnewtickets").then(
      (response) => {
        setTotalTickets(response.data.totalTickets);
      }
    ).catch(console.log)
    }, []);
    return(
        <>
            <Box bg={colors.boxColor} color='white' borderRadius='md'
                style={{
                    boxShadow: '1px 2px 9px black',
                  }}
            >
                <Flex p='3' justifyContent="space-between" as='b' fontSize='5xl' alignItems='center'>
                <Text>Goal:</Text>
                <Text>{( (totalTickets / TicketGoal) * 100 ).toFixed(2)}%</Text>
                </Flex>
                <Divider />
                {/* <Center> */}
                    <Box p='3'>
                <Center>This is our very first round and we will announce winners when we reached our goal sale. Buy now and </Center>
                <Center fontSize='xl' as='b' color="#beef00" mt='2'>"Be Our First Winner"</Center>
                </Box>
                {/* </Center> */}
            </Box>
        </>
    )
};



export default FirstRound;