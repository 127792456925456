import { useState, useEffect } from 'react';
import { Box, GridItem, Flex, FormControl, Select, Button, Grid, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton, FormLabel, InputGroup, InputRightElement, Input, Center, Divider, Spinner, useBreakpointValue } from '@chakra-ui/react'
import { QuestionIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';
import useRefreshToken from "../hooks/useRefreshToken";

const toggleMemberAtIndex = (memberArray, index) => {
  const newMemberArray = [...memberArray];
  newMemberArray[index] = !newMemberArray[index];
  return newMemberArray;
};
const BuyTicket1 = () => {
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [value3, setValue3] = useState('');
  const [value4, setValue4] = useState('');
  const [value5, setValue5] = useState('');
  const [value6, setValue6] = useState('');
  const [value7, setValue7] = useState('');
  const [value8, setValue8] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [chooseRandomTicketsFor10, setChooseRandomTicketsFor10] = useState(Array(50).fill(false));
  const [chooseRandomTicketsFor100, setChooseRandomTicketsFor100] = useState(Array(200).fill(false));
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [choseRange, setChoseRange] = useState(Array(10).fill(false));
  const [chooseOneFromTen, setChooseOneFromTen] = useState([true, false, false, false, false, false, false, false, false, false]); //work with handle10TicketRanges
  const [lessThan100, setLessThan100] = useState(false);
  const [moreThan100, setMoreThan100] = useState(false);
  const [randomGenerate, setRandomGenerate] = useState(false);
  const [rangeGenerate, setRangeGenerate] = useState(false);
  const [tempTickets, setTempTickets] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [numTickets, setNumTickets] = useState(1);
  const [systemTickets, setSystemTickets] = useState([]);
  const [userTickets, setUserTickets] = useState([]);
  const [randomTicketsFor10, setRandomTicketsFor10] = useState([]);
  const [randomTicketsFor100, setRandomTicketsFor100] = useState([]);
  const [ticketsFor6Char, setTicketsFor6Char] = useState([]);
  const [notAvailableTickets, setNotAvailableTickets] = useState([]);
  const [availableTickets, setAvailableTickets] = useState([]);
  const [chooseTicketsFor6Char, setChooseTicketsFor6Char] = useState(Array(100).fill(false))
  const [numEnteredValues, setNumEnteredValues] = useState(0);
  const showDivider = useBreakpointValue({ base: true, md: false });
  const [currentRound, setCurrentRound] = useState();
  const [orderId, setOrderId] = useState();
  const currentDate = new Date();
  const refresh = useRefreshToken();

  // Format the date as MM-DD-YYYY
  const formattedDate = `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}`;

  // for modal
  const [isRandomGenerateModalOpen, setIsRandomGenerateModalOpen] = useState(false);
  const [isLastModalOpen, setIsLastModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
  const [is6CharModalOpen, setIs6CharModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const handleQuestionIconClick = () => {
    setIsModalOpen(true);
  };

  const handleChooseRandomTicketsFor10 = (index) => {
    const updatedMembers = toggleMemberAtIndex(chooseRandomTicketsFor10, index);
    setChooseRandomTicketsFor10(updatedMembers);
  };

  const handleChooseRandomTicketsFor100 = (index) => {
    const updatedMembers = toggleMemberAtIndex(chooseRandomTicketsFor100, index);
    setChooseRandomTicketsFor100(updatedMembers);
  };

  const handleChooseTicketsFor6Char = (index) => {
    const updatedMembers = toggleMemberAtIndex(chooseTicketsFor6Char, index);
    setChooseTicketsFor6Char(updatedMembers);
  };


  const handleChangeBooleanFor100Members = (isTrue, which100) => {
    let newArray = [];
    if (which100 === 1) {
      if (!isTrue) {
        newArray = Array(100).fill(false);
      } else {
        newArray = Array(100).fill(true);
      }
      newArray.push(...chooseRandomTicketsFor100.slice(100, 200));
    } else {
      newArray = chooseRandomTicketsFor100.slice(0, 100);
      if (!isTrue) {
        for (let i = 0; i < 100; i++) {
          newArray.push(false);
        }
      } else {
        for (let i = 0; i < 100; i++) {
          newArray.push(true);
        }
      }
    }
    setChooseRandomTicketsFor100(newArray);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const closeGenerateModal = () => {
    setIsGenerateModalOpen(false);
  };
  const handle6CharModalClose = () => {
    setIs6CharModalOpen(false);
    setTicketsFor6Char([]);
  };
  const handleRandomGenerateModalClose = () => {
    setIsRandomGenerateModalOpen(false);
    setIsGenerateModalOpen(true);
    setChooseRandomTicketsFor10(Array(50).fill(false));
    setTempTickets([]);
  };

  const handlePaymentModalClose = () => {
    setIsPaymentModalOpen(false);
  }

  // useEffect(async ()=>{
  //   await refresh();
  // },[])

  useEffect(() => {
    // Retrieve the tickets from the localStorage
    let storedTickets = localStorage.getItem('systemTickets');
    if (storedTickets) {
      let parsedTickets = JSON.parse(storedTickets);
      setSystemTickets(parsedTickets);
    }
  }, []);
  useEffect(() => {
    // Retrieve the tickets from the localStorage
    let storedTickets = localStorage.getItem('userTickets');
    if (storedTickets) {
      let parsedTickets = JSON.parse(storedTickets);
      setUserTickets(parsedTickets);
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
        try {
          const getRound = await axiosPrivate.get(`/roundsandprizes/getround`);
          setCurrentRound(getRound.data+1);
        } catch (error) {
          console.error("Error fetching round:", error);
        }
    };
    fetchData();
  }, []);

  const handleLessThan100 = () => {
    if (lessThan100) {
      return;
    } else {
      setMoreThan100(false);
      setLessThan100(true);
    }
  };
  const handleMoreThan100 = () => {
    if (moreThan100) {
      return;
    } else {
      setMoreThan100(true);
      setLessThan100(false);
    }
  };
  const handleRandomGenerate = () => {
    if (randomGenerate) {
      return;
    } else {
      setRandomGenerate(true);
      setRangeGenerate(false);
    }
  };
  const handleRangeGenerate = () => {
    if (rangeGenerate) {
      return;
    } else {
      setRandomGenerate(false);
      setRangeGenerate(true);
    }
  };

  const handleChangeLetter = (event) => {
    let result = event.target.value.replace(/[^a-z]/gi, '');
    if (event.target.id === 'value1') {
      setValue1(result);
      document.getElementById("value2").removeAttribute("disabled");
      if (result === "") {
        disableInputsAfter('value1');
      }
    } else if (event.target.id === 'value2') {
      setValue2(result);
      document.getElementById("value3").removeAttribute("disabled");
      //resetValuesAfter('value3');
      if (result === "") {
        disableInputsAfter('value2');
      }
    } else if (event.target.id === 'value3') {
      setValue3(result);
      document.getElementById("value4").removeAttribute("disabled");
      //resetValuesAfter('value4');
      if (result === "") {
        disableInputsAfter('value3');
      }
    } else if (event.target.id === 'value4') {
      setValue4(result);
      document.getElementById("value5").removeAttribute("disabled");
      //resetValuesAfter('value4');
      if (result === "") {
        disableInputsAfter('value4');
      }
    }
  };

  const handleChangeNumber = (event) => {
    let result = event.target.value;
    if (result.length > 1) {
      result = result.slice(0, 1);
    }
    if (event.target.id === 'value5') {
      setValue5(result);
      document.getElementById("value6").removeAttribute("disabled");
      //resetValuesAfter('value6');
      if (result === "") {
        disableInputsAfter('value5');
      }
    } else if (event.target.id === 'value6') {
      setValue6(result);
      document.getElementById("value7").removeAttribute("disabled");
      //resetValuesAfter('value7');
      if (result === "") {
        disableInputsAfter('value6');
      }
    } else if (event.target.id === 'value7') {
      setValue7(result);
      document.getElementById("value8").removeAttribute("disabled");
      //resetValuesAfter('value8');
      if (result === "") {
        disableInputsAfter('value7');
      }
    } else if (event.target.id === 'value8') {
      setValue8(result)
    }
  };
  const disableInputsAfter = (id) => {
    let valueIds = ['value1', 'value2', 'value3', 'value4', 'value5', 'value6', 'value7', 'value8'];
    let startIndex = valueIds.indexOf(id);

    for (let i = startIndex + 1; i < valueIds.length; i++) {
      let valueId = valueIds[i];
      let valueElement = document.getElementById(valueId);
      valueElement.disabled = true;
      if (i === 1) {
        setValue2('')
      } else if (i === 2) {
        setValue3('');
      } else if (i === 3) {
        setValue4('');
      } else if (i === 4) {
        setValue5('');
      } else if (i === 5) {
        setValue6('');
      } else if (i === 6) {
        setValue7('');
      } else if (i === 7) {
        setValue8('');
      }
    }
  };

  const handleSystemGenerate = async (numTickets) => {
    // checking 1999 to see if user already generated 2000 tickets
    // if (systemTickets.length > 999) return toast.error("You can only generate 1000 tickets in each box.");
    const generatedTickets = new Set(systemTickets);
    const enteredValues = [value1, value2, value3, value4, value5, value6, value7, value8];
    const countEnteredValues = enteredValues.filter(value => value !== "").length;

    const numUserValues = countEnteredValues;
    if (numUserValues < 6) {
      let newTicket = "";
      let attempts = 0;
      let foundInSystemTicket = false;
      let foundInUserTicket = false;

      while (attempts < 5) {
        newTicket = "";
        for (let i = 1; i <= numUserValues; i++) {
          const value = (eval(`value${i}`) || "").toUpperCase();
          newTicket += value;
        }
        if (numUserValues < 4) {
          const remainingLetters = 4 - numUserValues;
          for (let i = 0; i < remainingLetters; i++) {
            const randomLetter = String.fromCharCode(Math.floor(Math.random() * 26) + 65);
            newTicket += randomLetter;
          }
        }
        const remainingNumbers = 5 - newTicket.length;
        for (let i = 0; i < remainingNumbers; i++) {
          const randomDigit = Math.floor(Math.random() * 10);
          newTicket += randomDigit;
        }
        foundInSystemTicket = systemTickets.find(ticket => ticket.startsWith(newTicket));
        foundInUserTicket = userTickets.find(ticket => ticket.startsWith(newTicket));
        if (!foundInSystemTicket && !foundInUserTicket) {
          break;
        } else {
          attempts++;
        }
      }
      if (attempts === 5) {
        return toast.error("Cannot generate the same ticket twice.\nPlease change entries to make unique tickets.")
      }
      setIsLoading(true);
      try {
        const response = await axiosPrivate.get(`/newtickets/check1000tickets/${newTicket}`);
        const ticketFound = response.data;
        if (Array.isArray(ticketFound)) {
          const updatedTickets = [...tempTickets, ...ticketFound];
          setTempTickets(updatedTickets);
          setIsLoading(false);
          setIsLastModalOpen(true);
        } else {
          setIsLoading(false);
          toast.error(`Tickets that start with ${newTicket} are not available.`);
        }
      } catch (error) {
        console.error("Error checking ticket:", error);
        setIsLoading(false);
      }
    } else {
      // for 6 characters
      let newTicket = "";
      let attempts = 0;
      let foundInSystemTicket = false;
      let foundInUserTicket = false;

      while (attempts < 5) {
        newTicket = "";
        for (let i = 1; i <= numUserValues; i++) {
          const value = (eval(`value${i}`) || "").toUpperCase();
          newTicket += value;
        }
        foundInSystemTicket = systemTickets.find(ticket => ticket.startsWith(newTicket));
        foundInUserTicket = userTickets.find(ticket => ticket.startsWith(newTicket));
        if (!foundInSystemTicket && !foundInUserTicket) {
          break;
        } else {
          attempts++;
        }
      }
      if (attempts === 5) {
        return toast.error("Cannot generate the same ticket twice.\nPlease change entries to make unique tickets.")
      }
      setIsLoading(true);
      try {
        const response = await axiosPrivate.get(`/newtickets/check100tickets/${newTicket}`);
        const ticketFound = response.data;
        if (Array.isArray(ticketFound)) {
          setTicketsFor6Char(response.data)
          const updatedTickets = [...tempTickets, ...ticketFound];
          setTempTickets(updatedTickets);
          setIsLoading(false);
          setIs6CharModalOpen(true);
        } else {
          setIsLoading(false);
          toast.error(`Tickets that start with ${newTicket} are not available.`);
        }
      } catch (error) {
        console.error("Error checking ticket:", error);
        setIsLoading(false);
      }
    }
    const newTickets = [...generatedTickets];
    localStorage.setItem('systemTickets', JSON.stringify(newTickets));
    setSystemTickets(newTickets);
  };

  const handleGenerateClick = () => {
    const enteredValues = [value1, value2, value3, value4, value5, value6, value7, value8];
    const countEnteredValues = enteredValues.filter(value => value !== "").length;
    setNumEnteredValues(countEnteredValues);
    setAllSelected(countEnteredValues === 8);
    if (countEnteredValues === 7) {
      return toast.error("System generation only works for less than 7 characters.");
    } else if (countEnteredValues === 6) {
      handleSystemGenerate();
    } else if (countEnteredValues < 6) {
      setIsGenerateModalOpen(true);
    } else {
      // checking 999 to see if user already generated 1000 tickets
      if (userTickets.length > 1000) return toast.error("You can only generate 1000 tickets in each box.");
      const combination = enteredValues.slice(0, 8).join('');
      const isInSystemTickets = systemTickets.includes(combination);
      const isInUserTickets = userTickets.includes(combination);

      // This one is for user generate only. System generate is checked in backend
      if (isInSystemTickets || isInUserTickets) {
        return toast.error("Cannot generate the same ticket twice.\nPlease change entries to make unique tickets.")
      }
      setIsLoading(true);
      //Check if ticket exists in the database
      axiosPrivate.get(`/newTickets/checkoneticket/${combination}`)
        .then(response => {
          const isExist = response.data.isExist;
          if (isExist) {
            setIsLoading(false);
            handleRemoveError('Ticket already exists!');
          } else {
            userTickets.push(combination);
            localStorage.setItem('userTickets', JSON.stringify(userTickets));
            setIsLoading(false);
            handleSuccess("Generated");
            setValue1('');
            disableInputsAfter('value1');
          }
        })
        .catch(error => {
          handleRemoveError(error);
          setIsLoading(false);
        });
      // }
    }
  };

  const handleNumberChange = (event) => {
    const value = parseInt(event.target.value);
    setNumTickets(value);
  };
  const handleSystemGenerateRandom = async (numTickets) => {
    // checking 999 to see if the user already generated 1000 tickets
    if (systemTickets.length > 1000) {
      return toast.error("You can only generate 1000 tickets in each box.");
    }

    let enteredValues = [value1, value2, value3, value4, value5, value6, value7, value8];
    let countEnteredValues = enteredValues.filter(value => value !== "").join('');
    const ticketId = countEnteredValues;
    if (lessThan100) {
      setIsLoading(true);
      axiosPrivate.post('/newtickets/check50randomtickets', { ticketId })
        .then(response => {
          if (response.data.isExist) {
            setIsLoading(false);
            handleRemoveError(`Tickets that start with ${countEnteredValues} are not available.`);
          } else {
            setRandomTicketsFor10(response.data);
            setIsLoading(false);
            setIsGenerateModalOpen(false);
            setIsRandomGenerateModalOpen(true);
          }
        })
        .catch(error => {
          console.error('Error inserting ticket:', error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      axiosPrivate.post('/newtickets/check200randomtickets', { ticketId })
        .then(response => {
          if (response.data.isExist) {
            setIsLoading(false);
            handleRemoveError(`Tickets that start with ${countEnteredValues} are not available.`);
          } else {
            setRandomTicketsFor100(response.data);
            setIsLoading(false);
            setIsGenerateModalOpen(false);
            setIsRandomGenerateModalOpen(true);
          }
        })
        .catch(error => {
          console.error('Error inserting ticket:', error);
        });
    }
  };

  //For Second Step Modal
  const handleLastModalClose = () => {
    setIsLastModalOpen(false);
    setTempTickets([]);
    setChoseRange(Array(10).fill(false));
    if (numEnteredValues === 6) setLessThan100(false);
  };
  const handleChoosingRange = (rangeIndex) => {
    setChoseRange((prevArray) => {
      const newArray = [...prevArray];
      newArray[rangeIndex] = !newArray[rangeIndex];
      return newArray;
    });
  }
  const isAtLeastOneTrue = choseRange.some((value) => value === true);
  const isAtLeastOneTrueForRandomTicketsFor10 = chooseRandomTicketsFor10.some((value) => value === true);
  const isAtLeastOneTrueForRandomTicketsFor100 = chooseRandomTicketsFor100.some((value) => value === true);
  const trueIndex = chooseOneFromTen.findIndex((value) => value === true);
  const isTicketsFor6CharTrue = chooseTicketsFor6Char.some((value) => value === true)

  const render100TicketRanges = (ticketLength) =>
    Array.from({ length: Math.ceil(ticketLength / 100) }).map((_, rangeIndex) => {
      const startIndex = rangeIndex * 100;
      const endIndex = Math.min((rangeIndex + 1) * 100, ticketLength) - 1;
      const handleRangeClick = () => {
        handleChoosingRange(rangeIndex);
      };
      return (
        <Box p="2" my='1' bg="#434242" borderRadius="1rem" w="300px" display="flex" alignItems="center" justifyContent="space-between" onClick={handleRangeClick}>
          <span>{tempTickets[startIndex]}-{tempTickets[endIndex]}</span>
          {choseRange[rangeIndex] ?
            <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#beef00" }} /> :
            <FontAwesomeIcon icon={faCircle} style={{ color: "#F3EFE0" }} />
          }
        </Box>
      );
    });
  const setSingleTrue = (index) => {
    const updatedArray = chooseOneFromTen.map((_, i) => i === index);
    setChooseOneFromTen(updatedArray);
  };
  const render10TicketRanges = (ticketLength) =>
    Array.from({ length: 10 }).map((_, rangeIndex) => {
      const startIndex = rangeIndex * 10;
      const endIndex = Math.min((rangeIndex + 1) * 10, ticketLength) - 1;
      const handleRangeClick = () => {
        handleChoosingRange(rangeIndex);
      };
      return (
        <Box p="2" my='1' bg="#434242" borderRadius="1rem" w="300px" display="flex" alignItems="center" justifyContent="space-between" onClick={handleRangeClick}>
          <span>{tempTickets[(trueIndex * 100) + startIndex]}-{tempTickets[(trueIndex * 100) + endIndex]}</span>
          {choseRange[rangeIndex] ?
            <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#beef00" }} /> :
            <FontAwesomeIcon icon={faCircle} style={{ color: "#F3EFE0" }} />
          }
        </Box>
      );
    });

  const handleDeleteUserTicket = (ticketId) => {
    const updatedUserTickets = userTickets.filter(ticket => ticket !== ticketId);
    localStorage.setItem('userTickets', JSON.stringify(updatedUserTickets));
    setUserTickets(updatedUserTickets);
    handleSuccess("Removed");
  }

  const handleDeleteSystemTicket = (ticketId) => {
    const updatedSystemTickets = systemTickets.filter(ticket => ticket !== ticketId);
    localStorage.setItem('systemTickets', JSON.stringify(updatedSystemTickets));
    setSystemTickets(updatedSystemTickets);
    handleSuccess("Removed");
  }
  const addToSystemTicketsFor100Ranges = (rangeIndex) => {
    setIsLoading(true);

    setSystemTickets((prevSystemTickets) => {
      let updatedSystemTickets = [...prevSystemTickets];

      rangeIndex.forEach((chosen, index) => {
        if (chosen) {
          const startIndex = index * 100;
          const endIndex = startIndex + 99;
          const selectedTickets = tempTickets.slice(startIndex, endIndex + 1);
          if(systemTickets.length + selectedTickets.length > 1000){
            setIsLoading(false);
            return toast.error("You can only generate 1000 tickets in each box.");
          }else{
            updatedSystemTickets.push(...selectedTickets);
          }
        }
      });
      if(prevSystemTickets.length !== updatedSystemTickets.length){
        // Remove duplicates using a Set
      const uniqueUpdatedSystemTickets = [...new Set(updatedSystemTickets)];
      localStorage.setItem('systemTickets', JSON.stringify(uniqueUpdatedSystemTickets));
      return uniqueUpdatedSystemTickets;
      }else{
        return updatedSystemTickets;
      }
    });
    handleSuccess("Generated");
    handleLastModalClose();
      closeGenerateModal();
      setIsLoading(false);
  };
  const addToSystemTicketsFor10Ranges = (rangeIndexFor10, rangeIndexFor100) => {
    setIsLoading(true);
    setSystemTickets((prevSystemTickets) => {
      const updatedSystemTickets = [...prevSystemTickets];
      const hundredThMember = chooseOneFromTen.indexOf(true);
      for (let i = 0; i < rangeIndexFor10.length; i++) {
        if (rangeIndexFor10[i] === true) {
          let startIndex = (hundredThMember * 100) + (i * 10);
          const selectedTickets = tempTickets.slice(startIndex, startIndex + 10);
          if(systemTickets.length + selectedTickets.length > 1000){
            setIsLoading(false);
            return toast.error("You can only generate 1000 tickets in each box.");
          }else{
            updatedSystemTickets.push(...selectedTickets);
          }
          // updatedSystemTickets.push(...tempTickets.slice(startIndex, startIndex + 10));
        }
      }
      if(prevSystemTickets.length !== updatedSystemTickets.length){
        // Remove duplicates using a Set
      const uniqueUpdatedSystemTickets = [...new Set(updatedSystemTickets)];
      localStorage.setItem('systemTickets', JSON.stringify(uniqueUpdatedSystemTickets));
      return uniqueUpdatedSystemTickets;
      }else{
        return updatedSystemTickets;
      }
    });
    handleLastModalClose();
      closeGenerateModal();
      setIsLoading(false);
      handleSuccess("Generated");
  };


  const handleDeleteAllUserTickets = () => {

    if (userTickets.length === 0) {
      return handleRemoveError("You do not have any tickets to delete.");
    }
    setIsLoading(true);
    localStorage.setItem('userTickets', JSON.stringify([]));
    setUserTickets([]);
    setIsLoading(false);
    handleSuccess("Removed All Tickets");
  };

  const handleDeleteAllSystemTickets = () => {
    if (systemTickets.length === 0) {
      return handleRemoveError("You do not have any tickets to delete.")
    }
    setIsLoading(true);
    localStorage.setItem('systemTickets', JSON.stringify([]));
    setSystemTickets([]);
    setIsLoading(false);
    handleSuccess("Removed All Tickets");
  };

  const addToSystemTicketsForRandomTicketsFor10 = () => {
    setIsLoading(true);
  
    const trueIndicesArray = chooseRandomTicketsFor10.reduce((acc, value, index) => {
      if (value) {
        acc.push(index);
      }
      return acc;
    }, []);
  
    const selectedValues = trueIndicesArray.map((index) => randomTicketsFor10[index]);
  
    setIsLoading(false);
    if(systemTickets.length + selectedValues.length > 1000) return toast.error("You can only generate 1000 tickets in each box.")
    const updatedSystemTickets = [...systemTickets, ...selectedValues];
    const uniqueUpdatedSystemTickets = [...new Set(updatedSystemTickets)];
  
    localStorage.setItem('systemTickets', JSON.stringify(uniqueUpdatedSystemTickets));
  
    setSystemTickets(uniqueUpdatedSystemTickets);
  
    setRandomTicketsFor10([]);
    handleSuccess("Generated");
    setChooseRandomTicketsFor10(Array(50).fill(false));
    handleRandomGenerateModalClose();
    closeGenerateModal();
  };
  

  const addToSystemTicketsForRandomTicketsFor100 = () => {
    setIsLoading(true);
    const trueIndicesArray = chooseRandomTicketsFor100.reduce((acc, value, index) => {
      if (value) {
        acc.push(index);
      }
      return acc;
    }, []);
    const selectedValues = trueIndicesArray.map((index) => randomTicketsFor100[index]);
    setIsLoading(false);
    if(systemTickets.length + selectedValues.length > 1000) return toast.error("You can only generate 1000 tickets in each box.")
    const updatedSystemTickets = [...systemTickets, ...selectedValues];
  const uniqueUpdatedSystemTickets = [...new Set(updatedSystemTickets)];

  localStorage.setItem('systemTickets', JSON.stringify(uniqueUpdatedSystemTickets));

  setSystemTickets(uniqueUpdatedSystemTickets);
    setRandomTicketsFor100([]);
    handleSuccess("Generated");
    setChooseRandomTicketsFor100(Array(200).fill(false));
    handleRandomGenerateModalClose();
    closeGenerateModal();
  }

  const renderTicketsFor6Char = () => {
    setIsLoading(true);
    const trueIndicesArray = chooseTicketsFor6Char.reduce((acc, value, index) => {
      if (value) {
        acc.push(index);
      }
      return acc;
    }, []);
    const selectedValues = trueIndicesArray.map((index) => ticketsFor6Char[index]);
    setIsLoading(false);
    if(systemTickets.length + selectedValues.length > 1000) return toast.error("You can only generate 1000 tickets in each box.")
    const updatedSystemTickets = [...systemTickets, ...selectedValues];
  const uniqueUpdatedSystemTickets = [...new Set(updatedSystemTickets)];

  localStorage.setItem('systemTickets', JSON.stringify(uniqueUpdatedSystemTickets));

  setSystemTickets(uniqueUpdatedSystemTickets);
    setTicketsFor6Char([]);
    handleSuccess("Generated");
    setChooseTicketsFor6Char(Array(100).fill(false));
    handle6CharModalClose();
  }

  const checkIsTicketsAvailable = async () => {
    await refresh();
    const userId = auth.user.userId;
    const boughtTickets = auth.authNewTickets.length;
    const purchaseDate = new Date();
    setOrderId(userId + "|" + purchaseDate);
    setIsLoading(true);
    const allTickets = [...systemTickets, ...userTickets];
    if (allTickets.length === 0) {
      setIsLoading(false);
      return handleRemoveError("You do not have any tickets.");
    }
    else if(allTickets.length + boughtTickets > 2000){
      setIsLoading(false);
      return handleRemoveError(`You cannot buy more than 2000 tickets in one round. You already bought ${boughtTickets} tickets. You can still buy ${2000-boughtTickets} tickets for this round.`);
    }
    axiosPrivate
      .post('/newtickets/checkalltickets', { allTickets })
      .then(response => {
        setNotAvailableTickets(response.data.foundTicketIds);
        setAvailableTickets(response.data.notFoundTicketIds);

        if (response.data.notFoundTicketIds.length === 0) {
          setIsLoading(false);
          handleRemoveError("All generated tickets are no longer available.\nSomeone already bought it.");
          localStorage.setItem('systemTickets', JSON.stringify([]));
          setSystemTickets([]);
          localStorage.setItem('userTickets', JSON.stringify([]));
          setUserTickets([]);
        } else {
          setIsLoading(false);
          setIsPaymentModalOpen(true);
        }
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error inserting ticket:', error);
      });
  };

  const postToTempTickets = async () => {
    setIsLoading(true);
    const userId = auth.user.userId;
    const purchaseDate = new Date();
    const totalTickets = availableTickets.length;
    const formattedTickets = availableTickets.map((ticket) => ({
      ticketId: ticket,
      userId: userId,
      purchaseDate: purchaseDate,
      prize: 0,
      paid: false,
      status: 'normal',
      round: currentRound,
    }));
  
    try {
      await axiosPrivate.post("/newtickets/postalltickets", {
        tickets: formattedTickets,
      });
  
      await axiosPrivate.post("/orders/neworder", {
        userId: userId,
        purchaseDate: purchaseDate,
        totalTickets: totalTickets,
      });
      await refresh();
      localStorage.setItem('systemTickets', JSON.stringify([]));
      setSystemTickets([]);
      localStorage.setItem('userTickets', JSON.stringify([]));
      setUserTickets([]);
      setIsLoading(false);
      setIsPaymentModalOpen(false);
      toast.success("Thank you for your purchase.");
    } catch (error) {
      setIsLoading(false);
      setIsPaymentModalOpen(false);
      console.error(error);
      toast.error(error.message);
    }
  };
  

  const handleSuccess = (type) => {
    toast.success(type + ' Successfully!', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
  const handleRemoveError = (type) => {
    toast.error(type, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  const totalSystemTickets = systemTickets.length;
  const disableGenerateButton = ((lessThan100 && !moreThan100) || (!lessThan100 && moreThan100)) && ((randomGenerate && !rangeGenerate) || (!randomGenerate && rangeGenerate));

  return (
    <div>
      <Modal isOpen={isLoading} isCentered>
        <ModalContent h='200px' w='200px' bg='#262626' color='white'>
          <ModalBody>
            <Box mt='30%'>
              <Center><Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='#beef00' size='xl' /></Center>
              <Center fontSize='xl' as='b'>Loading</Center>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isGenerateModalOpen}>
        <ModalOverlay />
        <ModalContent style={{ backgroundColor: '#1A202C', color: 'white', width: '95%' }}>
          <ModalHeader pt="1rem">How many tickets do you want to generate?</ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <FormControl>
              <FormLabel>Choose one from the followings</FormLabel>
              <Center mb='2rem'>
                <Box>
                  <Box mb='1rem' p="4" bg="#434242" borderRadius="1rem" w="300px" display="flex" alignItems="center" justifyContent="space-between" onClick={handleLessThan100}>
                    <span>100 or less than 100 tickets</span>
                    {lessThan100 ?
                      <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#beef00" }} /> :
                      <FontAwesomeIcon icon={faCircle} style={{ color: "#F3EFE0" }} />
                    }
                  </Box>
                  <Box p="4" bg="#434242" borderRadius="1rem" w="300px" display="flex" alignItems="center" justifyContent="space-between" onClick={handleMoreThan100}>
                    <span>More than 100 tickets</span>
                    {moreThan100 ?
                      <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#beef00" }} /> :
                      <FontAwesomeIcon icon={faCircle} style={{ color: "#F3EFE0" }} />
                    }
                  </Box>
                </Box>
              </Center>
              <Flex>
                <FormLabel>Random Generate or Range Generate</FormLabel>
                <Box as='b'>
                  <QuestionIcon cursor='pointer' />
                </Box>
              </Flex>
              <Center>
                <Box>
                  <Box mb='1rem' p="4" bg="#434242" borderRadius="1rem" w="300px" display="flex" alignItems="center" justifyContent="space-between" onClick={handleRandomGenerate}>
                    <span>Random Generate</span>
                    {randomGenerate ?
                      <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#beef00" }} /> :
                      <FontAwesomeIcon icon={faCircle} style={{ color: "#F3EFE0" }} />
                    }
                  </Box>
                  <Box p="4" bg="#434242" borderRadius="1rem" w="300px" display="flex" alignItems="center" justifyContent="space-between" onClick={handleRangeGenerate}>
                    <span>Range Generate</span>
                    {rangeGenerate ?
                      <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#beef00" }} /> :
                      <FontAwesomeIcon icon={faCircle} style={{ color: "#F3EFE0" }} />
                    }
                  </Box>
                </Box>
              </Center>
            </FormControl>
          </ModalBody>
          <ModalFooter color='black'>
            <Button onClick={closeGenerateModal} mr="2" bg="#F0EEED">
              Cancel
            </Button>
            <Button
              bg={disableGenerateButton ? '#beef00' : '#808080'}
              disabled={!disableGenerateButton}
              onClick={() => {
                if (disableGenerateButton) {
                  if (rangeGenerate) {
                    handleSystemGenerate(numTickets);
                  } else {
                    handleSystemGenerateRandom(numTickets);
                  }
                }
              }}
              cursor={disableGenerateButton ? 'pointer' : 'not-allowed'}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isPaymentModalOpen}>
        <ModalOverlay />
        <ModalContent style={{ backgroundColor: '#1A202C', color: 'white', width: '95%' }}>
          <ModalBody maxHeight="500px" overflowY="scroll">
            {notAvailableTickets.length > 0 &&
              <>
                <Center as='b' fontSize={{ base: 'sm', lg: 'xl' }} my='2'>Some tickets are not available.</Center>
                <Center as='b' fontSize={{ base: 'sm', lg: 'xl' }} my='2'>Do you want to continue the purchase?</Center>
                <Box borderWidth="1px" borderRadius="lg" h="200px" overflowY="scroll" border='2px solid #beef00' m='2'>
                  <Flex justifyContent="space-between" alignItems="center" mb='1rem' bg='#F6E05E' py='2' px='4' position="sticky" top="0" zIndex='1'>
                    <Flex alignItems='center'>
                      <Text fontSize={{ base: 'xs', md: 'sm', lg: 'lg' }} mr={['1', '3']} pt={['1', '1']} as='b' color='black'>The following tickets are not available.</Text>
                    </Flex>
                  </Flex>
                  <Grid
                    templateColumns={{
                      base: "repeat(2, 1fr)",
                      md: "repeat(3, 1fr)",
                      // lg: "repeat(6, 1fr)"
                    }}
                    gap={{base: 5, md:2}}
                    m='3'
                  >
                    {notAvailableTickets.map((ticket, index) => (
                      <Center key={index}>
                        <Box border='white solid 1px' py='2' px='4' borderRadius='md'>{ticket}</Box>
                        {/* <InputGroup justifyContent='center'> */}
                        {/* <Input justifySelf='center' placeholder={ticket} fontSize={['xs', 'md']} fontWeight="bold" userSelect="none" pointerEvents="none" pl='4' /> */}
                        {/* </InputGroup> */}
                      </Center>
                    ))}
                  </Grid>
                  <br /><br />
                </Box>
                <br />
              </>
            }
            <Center as='b' fontSize={'xl'} mb='2'>Order Summary</Center>
            <Flex justifyContent='space-between' mb='1'>
              <Box>Order ID: </Box>
              {orderId && <Box>{orderId.substr(0,15)+"..."}</Box>}
            </Flex>
            <Flex justifyContent='space-between' mb='1'>
              <Box>Order Date: </Box>
              <Box>{formattedDate}</Box>
            </Flex>
            <Flex justifyContent='space-between'>
              <Box>Total Charsets: </Box>
              <Box>{availableTickets.length}</Box>
            </Flex>
            <Flex justifyContent='space-between'>
              <Box>Price: </Box>
              <Box>${availableTickets.length*2}</Box>
            </Flex>
          </ModalBody>
          <ModalFooter color='black'>
            <Button onClick={handlePaymentModalClose} mr="2" bg="#F0EEED">
              Cancel
            </Button>
            <Button
              bg={'#beef00'}
              onClick={() => { postToTempTickets() }}
              cursor={'pointer'}
            >
              Purchase
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isRandomGenerateModalOpen}>
        <ModalOverlay />
        <ModalContent style={{ backgroundColor: '#1A202C', color: 'white', width: '95%' }}>
          {randomGenerate && moreThan100 &&
            <ModalBody maxHeight="430px" overflowY="scroll">
              <Center as='b' fontSize='xl' my='2'>Please choose the ticket you want and click generate.</Center>
              <Box borderWidth="1px" borderRadius="lg" h="300px" overflowY="scroll" border='2px solid #beef00' m='2'>
                <Flex justifyContent="space-between" alignItems="center" mb='1rem' bg='#F6E05E' py='2' px='4' position="sticky" top="0" zIndex='1'>
                  <Flex alignItems='center'>
                    <Text fontSize={{ base: 'xs', md: 'sm', lg: 'lg' }} mr={['1', '3']} pt={['1', '1']} as='b' color='black'>Select All 100 Tickets</Text>
                  </Flex>
                  <Flex onClick={() => {
                    if (chooseRandomTicketsFor100.slice(0, 100).every(item => item === true)) {
                      handleChangeBooleanFor100Members(false, 1);
                    } else {
                      handleChangeBooleanFor100Members(true, 1);
                    }
                  }}
                  >
                    {chooseRandomTicketsFor100.slice(0, 100).every(item => item === true) ?
                      <FontAwesomeIcon icon={faCircleCheck} style={{ color: "black" }} /> :
                      <FontAwesomeIcon icon={faCircle} style={{ color: "gray" }} />
                    }
                  </Flex>
                </Flex>
                <Grid
                  templateColumns={{
                    base: "repeat(2, 1fr)"
                  }}
                  gap={5}
                  m='3'
                >
                  {randomTicketsFor100.slice(0, 100).map((ticket, index) => (
                    <div key={index}>
                      <InputGroup>
                        <Input placeholder={ticket} fontSize={['xs', 'md']} fontWeight="bold" userSelect="none" pointerEvents="none" pl='4' />
                        <InputRightElement onClick={() => handleChooseRandomTicketsFor100(index)}>
                          {chooseRandomTicketsFor100[index] ?
                            <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#beef00" }} /> :
                            <FontAwesomeIcon icon={faCircle} style={{ color: "#F3EFE0" }} />
                          }
                        </InputRightElement>
                      </InputGroup>
                    </div>
                  ))}
                </Grid>
                <br /><br />
              </Box>
              <Box borderWidth="1px" borderRadius="lg" h="300px" overflowY="scroll" border='2px solid #beef00' m='2'>
                <Flex justifyContent="space-between" alignItems="center" mb='1rem' bg='#F6E05E' py='2' px='4' position="sticky" top="0" zIndex='1'>
                  <Flex alignItems='center'>
                    <Text fontSize={{ base: 'xs', md: 'sm', lg: 'lg' }} mr={['1', '3']} pt={['1', '1']} as='b' color='black'>Select All 100 Tickets</Text>
                  </Flex>
                  <Flex onClick={() => {
                    if (chooseRandomTicketsFor100.slice(100, 200).every(item => item === true)) {
                      handleChangeBooleanFor100Members(false, 2);
                    } else {
                      handleChangeBooleanFor100Members(true, 2);
                    }
                  }}
                  >
                    {chooseRandomTicketsFor100.slice(100, 200).every(item => item === true) ?
                      <FontAwesomeIcon icon={faCircleCheck} style={{ color: "black" }} /> :
                      <FontAwesomeIcon icon={faCircle} style={{ color: "gray" }} />
                    }
                  </Flex>
                </Flex>
                <Grid
                  templateColumns={{
                    base: "repeat(2, 1fr)"
                  }}
                  gap={5}
                  m='3'
                >
                  {randomTicketsFor100.slice(100, 200).map((ticket, index) => (
                    <div key={index}>
                      <InputGroup>
                        <Input placeholder={ticket} fontSize={['xs', 'md']} fontWeight="bold" userSelect="none" pointerEvents="none" pl='4' />
                        <InputRightElement onClick={() => handleChooseRandomTicketsFor100(100 + index)}>
                          {chooseRandomTicketsFor100[100 + index] ?
                            <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#beef00" }} /> :
                            <FontAwesomeIcon icon={faCircle} style={{ color: "#F3EFE0" }} />
                          }
                        </InputRightElement>
                      </InputGroup>
                    </div>
                  ))}
                </Grid>
                <br /><br />
              </Box>
            </ModalBody>
          }
          {randomGenerate && lessThan100 &&
            <ModalBody maxHeight="500px" overflowY="scroll">
              <Center as='b' fontSize={{ base: 'sm', lg: 'xl' }} my='2'>Please choose the ticket you want and click generate.</Center>
              <Box borderWidth="1px" borderRadius="lg" h="300px" overflowY="scroll" border='2px solid #beef00' m='2'>
                <Flex justifyContent="space-between" alignItems="center" mb='1rem' bg='#F6E05E' py='2' px='4' position="sticky" top="0" zIndex='1'>
                  <Flex alignItems='center'>
                    <Text fontSize={{ base: 'xs', md: 'sm', lg: 'lg' }} mr={['1', '3']} pt={['1', '1']} as='b' color='black'>Select All 50 Tickets</Text>
                  </Flex>
                  <Flex onClick={() => {
                    if (chooseRandomTicketsFor10.every(item => item === true)) {
                      setChooseRandomTicketsFor10(Array(50).fill(false));
                    } else {
                      setChooseRandomTicketsFor10(Array(50).fill(true));
                    }
                  }}
                  >
                    {chooseRandomTicketsFor10.every(item => item === true) ?
                      <FontAwesomeIcon icon={faCircleCheck} style={{ color: "black" }} /> :
                      <FontAwesomeIcon icon={faCircle} style={{ color: "gray" }} />
                    }
                  </Flex>
                </Flex>
                <Grid
                  templateColumns={{
                    base: "repeat(2, 1fr)"
                  }}
                  gap={5}
                  m='3'
                >
                  {randomTicketsFor10.map((ticket, index) => (
                    <div key={index}>
                      <InputGroup>
                        <Input placeholder={ticket} fontSize={['xs', 'md']} fontWeight="bold" userSelect="none" pl='4' />
                        <InputRightElement onClick={() => handleChooseRandomTicketsFor10(index)}>
                          {chooseRandomTicketsFor10[index] ?
                            <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#beef00" }} /> :
                            <FontAwesomeIcon icon={faCircle} style={{ color: "#F3EFE0" }} />
                          }
                        </InputRightElement>
                      </InputGroup>
                    </div>
                  ))}
                </Grid>
                <br /><br />
              </Box>
            </ModalBody>
          }
          <ModalFooter color='black'>
            <Button onClick={handleRandomGenerateModalClose} mr="2" bg="#F0EEED">
              Cancel
            </Button>
            <Button
              bg={(isAtLeastOneTrueForRandomTicketsFor10 || isAtLeastOneTrueForRandomTicketsFor100) ? '#beef00' : '#808080'}
              disabled={!(isAtLeastOneTrueForRandomTicketsFor10 || isAtLeastOneTrueForRandomTicketsFor100)}
              cursor={(isAtLeastOneTrueForRandomTicketsFor10 || isAtLeastOneTrueForRandomTicketsFor100) ? 'pointer' : 'not-allowed'}
              onClick={() => {
                if (moreThan100) {
                  addToSystemTicketsForRandomTicketsFor100();
                } else {
                  addToSystemTicketsForRandomTicketsFor10();
                }
              }}
            >
              Generate
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={is6CharModalOpen}>
        <ModalOverlay />
        <ModalContent style={{ backgroundColor: '#1A202C', color: 'white', width: '95%' }}>
          <ModalBody maxHeight="400px" overflowY="scroll">
            <Center as='b' fontSize={{ base: 'sm', lg: 'xl' }} my='2'>Please choose the ticket you want and click generate.</Center>
            <Box borderWidth="1px" borderRadius="lg" h="300px" overflowY="scroll" border='2px solid #beef00' m='2'>
              <Flex justifyContent="space-between" alignItems="center" mb='1rem' bg='#F6E05E' py='2' px='4' position="sticky" top="0" zIndex='1'>
                <Flex alignItems='center'>
                  <Text fontSize={{ base: 'xs', md: 'sm', lg: 'lg' }} mr={['1', '3']} pt={['1', '1']} as='b' color='black'>Select All 100 Tickets</Text>
                </Flex>
                <Flex onClick={() => {
                  if (chooseTicketsFor6Char.every(item => item === true)) {
                    setChooseTicketsFor6Char(Array(100).fill(false));
                  } else {
                    setChooseTicketsFor6Char(Array(100).fill(true));
                  }
                }}
                >
                  {chooseTicketsFor6Char.every(item => item === true) ?
                    <FontAwesomeIcon icon={faCircleCheck} style={{ color: "black" }} /> :
                    <FontAwesomeIcon icon={faCircle} style={{ color: "gray" }} />
                  }
                </Flex>
              </Flex>
              <Grid
                templateColumns={{
                  base: "repeat(2, 1fr)"
                }}
                gap={5}
                m='3'
              >
                {ticketsFor6Char.map((ticket, index) => (
                  <div key={index}>
                    <InputGroup>
                      <Input placeholder={ticket} fontSize={['xs', 'md']} fontWeight="bold" userSelect="none" pointerEvents="none" pl='4' />
                      <InputRightElement onClick={() => handleChooseTicketsFor6Char(index)}>
                        {chooseTicketsFor6Char[index] ?
                          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#beef00" }} /> :
                          <FontAwesomeIcon icon={faCircle} style={{ color: "#F3EFE0" }} />
                        }
                      </InputRightElement>
                    </InputGroup>
                  </div>
                ))}
              </Grid>
            </Box>
          </ModalBody>
          <ModalFooter color='black'>
            <Button onClick={handle6CharModalClose} mr="2" bg="#F0EEED">
              Cancel
            </Button>
            <Button
              bg={isTicketsFor6CharTrue ? '#beef00' : '#808080'}
              disabled={!isTicketsFor6CharTrue}
              cursor={isTicketsFor6CharTrue ? 'pointer' : 'not-allowed'}
              onClick={() => { renderTicketsFor6Char() }}
            >
              Generate
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>



      <Modal isOpen={isLastModalOpen && tempTickets.length > 0}>
        <ModalOverlay />
        <ModalContent overflowY="scroll" style={{ backgroundColor: '#1A202C', color: 'white', width: '95%' }}>
          {rangeGenerate && moreThan100 &&
            <ModalBody>
              <Text as='b' fontSize='lg'>Each range contains 100 tickets.</Text><br />
              <Center mt='3'>
                <Box>
                  {render100TicketRanges(tempTickets.length)}
                </Box>
              </Center>
            </ModalBody>}
          {rangeGenerate && lessThan100 &&
            <ModalBody>
              <Center as='b' fontSize='xl' my='2'>Each range contains 10 tickets.</Center>
              <Center as='b' mt='2'>Change Range</Center>
              <Center mt='1' mb='3'><Divider bg='#16FF00' w='45%' h='2px' /></Center>
              <Box>
                <Grid templateColumns='repeat(5, 1fr)' gap={6} mb='2'>
                  {chooseOneFromTen.map((value, index) => (
                    <Box
                      key={index}
                      py='1'
                      px='2'
                      border='1px solid black'
                      onClick={() => setSingleTrue(index)}
                      bg={value ? "#beef00" : "black"}
                      color={value ? "black" : "white"}
                      borderRadius='5px'
                    >
                      <Center>
                        {index}
                      </Center>
                    </Box>
                  ))}
                </Grid>
              </Box>
              <Center>
                <Box>
                  {render10TicketRanges(tempTickets.length)}
                </Box>
              </Center>
            </ModalBody>
          }
          <ModalFooter color='black'>
            <Button onClick={handleLastModalClose} mr="2" bg="#F0EEED">
              Cancel
            </Button>
            <Button
              bg={(isAtLeastOneTrue) ? '#beef00' : '#808080'}
              disabled={!(isAtLeastOneTrue)}
              cursor={(isAtLeastOneTrue) ? 'pointer' : 'not-allowed'}
              onClick={() => {
                if (moreThan100) {
                  addToSystemTicketsFor100Ranges(choseRange);
                } else if (lessThan100) {
                  addToSystemTicketsFor10Ranges(choseRange, chooseOneFromTen);
                }
              }}
            >
              Generate
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent style={{ backgroundColor: '#1A202C', color: 'white', width: '95%' }}>
          <ModalHeader>How to generate?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Box p='3' borderRadius='md'>
      <Text>
        You can buy tickets on our website using the following methods:
      </Text>
      <Box pl='4' mt='2'>
        <Text>
          1. Generate Your Own Tickets:
        </Text>
        <Text ml='4'>
          - Input 8 characters (4 letters + 4 numbers) to create a specific ticket.
        </Text>
        <Text ml='4'>
          - Each unique combination of characters corresponds to a unique ticket.
        </Text>
        <Text>
          2. System Generated Tickets:
        </Text>
        <Text ml='4'>
          - Input 0 to 5 characters to have the system generate tickets for you.
        </Text>
        <Text ml='4'>
          - System generates tickets using both random and range methods.
        </Text>
        <Text ml='4'>
          - If you enter 6 characters, system generates the remaining 100 tickets (From XXXXXX00 to XXXXXX99).
        </Text>
        <Text>
          Note:
        </Text>
        <Text ml='4'>
          - You cannot input 7 characters to generate.
        </Text>
      </Box>
    </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex textAlign='center' my={5} justifyContent='center'>
        <Box as='b' fontSize='2xl' pt='0.5' pr='2' color='white' cursor='pointer'>
          HOW TO GENERATE
        </Box>
        <Box as='b' fontSize='2xl' onClick={handleQuestionIconClick}>
          <QuestionIcon color='#beef00' cursor='pointer' />
        </Box>
      </Flex>
      <Box bg='#1A202C' px={{ base: '2rem', md: '0.1rem', lg: '1.6rem' }} py='2rem' borderRadius='lg' mb='2rem'>
        <Grid templateColumns='repeat(9, 1fr)'>
          <GridItem colSpan={{ base: 9, md: 4 }} justifyContent="center" ml={{ md: '4' }}>
            <Flex flexDirection="row" mb={['4', '0']}>
              {[1, 2, 3, 4].map((num) => (
                <FormControl key={`value${num}`}>
                  <Select
                    id={`value${num}`}
                    placeholder=""
                    value={num === 1 ? value1 : num === 2 ? value2 : num === 3 ? value3 : value4}
                    onChange={handleChangeLetter}
                    w={{ base: '65px', md: '63px', lg: '70px' }}
                    h='3rem'
                    border='2px solid #beef00'
                    _focus={{ boxShadow: "none", borderColor: "blue.500" }}
                    color='white'
                    isDisabled={num === 2 || num === 3 || num === 4}
                  >
                    <option value="" ></option>
                    {Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i)).map((letter) => (
                      <option key={letter} value={letter}><Text as='b'>{letter}</Text></option>
                    ))}
                  </Select>
                </FormControl>
              ))}
            </Flex>
          </GridItem>
          <GridItem colSpan={{ base: 9, md: 4 }} justifyItems="center">
            <Flex flexDirection="row" mb={['4', '0']}>
              {[5, 6, 7, 8].map((num) => (
                <FormControl key={`value${num}`}>
                  <Select
                    id={`value${num}`}
                    placeholder=""
                    value={
                      num === 5 ? value5 :
                        num === 6 ? value6 :
                          num === 7 ? value7 : value8
                    }
                    onChange={handleChangeNumber}
                    // w={{ base: '61px', md: '63px', lg: '70px' }}
                    w={{ base: '65px', md: '63px', lg: '70px' }}
                    h='3rem'
                    border='2px solid #beef00'
                    _focus={{ boxShadow: "none", borderColor: "blue.500" }}
                    color='white'
                    isDisabled={true}
                  >
                    <option value="" ></option>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
                      <option key={number} value={number} style={{ fontWeight: 'bold' }}>{number}</option>
                    ))}
                  </Select>

                </FormControl>
              ))}
            </Flex>
          </GridItem>
          <GridItem colSpan={{ base: 9, md: 1 }} ml={['0', 'auto']} mr='4'>
            <Grid justifyContent={{ base: 'center', md: 'flex-end' }}>
              <Button onClick={() => { auth.user ? handleGenerateClick() : handleRemoveError("Login or sign up to buy tickets.") }} style={{}} px={'3rem'} m={['0.5%', '1%']} h={['48px', '48px']} bg='#beef00' fontSize={{ base: 'sm', md: 'md', lg: 'lg' }} boxShadow='md'>Generate</Button>
            </Grid>
          </GridItem>
        </Grid>
      </Box>
      <Box bg='#1A202C' borderRadius='lg' p='2' >
        <Flex flexDir='column'>
          <Flex flexDir="column" w="100%" mb={{ base: '4', md: '0' }}>
            <Box borderWidth="1px" borderRadius="lg" h="300px" overflowY="scroll" border='2px solid #beef00' m='2'>
              <Flex justifyContent="space-between" alignItems="center" mb='1rem' bg='#F6E05E' py='2' px='4' position="sticky" top="0" zIndex='2'>
                <Flex alignItems='center'>
                  <Text fontSize={{ base: 'xs', md: 'sm', lg: 'lg' }} mr={['1', '3']} pt={['1', '1']} as='b'>You generated</Text>
                  <Text p='2' border='1px solid black' borderRadius='md' fontWeight='bold' fontSize={{ base: 'xs', md: 'sm', lg: 'lg' }}>{userTickets.length}</Text>
                  <Text fontSize={{ base: 'xs', md: 'sm', lg: 'lg' }} mx={['1', '3']} pt={['1', '1']} as='b'>tickets</Text>
                </Flex>
                <Flex>
                  <Button size="sm" colorScheme={"red"} onClick={() => { auth.user ? handleDeleteAllUserTickets() : handleRemoveError("Login or sign up to buy tickets.") }}>
                    Delete
                  </Button>
                </Flex>
              </Flex>
              <Grid
                templateColumns={{
                  base: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                  lg: "repeat(6, 1fr)"
                }}
                gap={8}
                m='3'
              >
                {userTickets.map((ticket, index) => (
                  <div key={index}>
                    <InputGroup>
                      <Input placeholder={ticket} roundedRight="0" fontSize={['sm', 'md']} fontWeight="bold" userSelect="none" pointerEvents="none" pl={{ sm: '0', md: '2' }} textAlign={"center"} />
                      <InputRightElement bg='#1A202C'>
                        <Button size="md" roundedLeft="0" colorScheme={"red"} onClick={() => handleDeleteUserTicket(ticket)}>
                          X
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </div>
                ))}
              </Grid>
            </Box>
          </Flex>
          <Flex flexDir="column" w="100%" mb={{ base: '4', md: '0' }}>
            <Box borderWidth="1px" borderRadius="lg" h="300px" overflowY="scroll" border='2px solid #beef00' m='2'>
              <Flex justifyContent="space-between" alignItems="center" mb='1rem' bg='#F6E05E' py='2' px='4' position="sticky" top="0" zIndex='1'>
                <Flex alignItems='center'>
                  <Text fontSize={{ base: 'xs', md: 'sm', lg: 'lg' }} mr={['1', '3']} pt={['1', '1']} as='b'>System generated</Text>
                  <Text p='2' border='1px solid black' borderRadius='md' fontWeight='bold' fontSize={{ base: 'xs', md: 'sm', lg: 'lg' }}>{totalSystemTickets}</Text>
                  <Text fontSize={{ base: 'xs', md: 'sm', lg: 'lg' }} mx={['1', '3']} pt={['1', '1']} as='b'>tickets</Text>
                </Flex>
                <Flex>
                  <Button size="sm" colorScheme={"red"} onClick={() => { auth.user ? handleDeleteAllSystemTickets() : handleRemoveError("Login or sign up to buy tickets.") }}>
                    Delete
                  </Button>
                </Flex>
              </Flex>
              <Grid
                templateColumns={{
                  base: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                  lg: "repeat(6, 1fr)"
                }}
                gap={8}
                m='3'

              >
                {systemTickets.map((ticket, index) => (
                  <div key={index}>
                    <InputGroup>
                      <Input placeholder={ticket} roundedRight="0" fontSize={['sm', 'md']} fontWeight="bold" userSelect="none" pointerEvents="none" pl={{ sm: '0', md: '2' }} textAlign={"center"} />
                      <InputRightElement bg='#1A202C'>
                        <Button size="md" roundedLeft="0" colorScheme={"red"} onClick={() => handleDeleteSystemTicket(ticket)}>
                          X
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </div>
                ))}
              </Grid>
            </Box>
          </Flex>
        </Flex>
        <Grid
          templateColumns={['1fr', '1fr 1fr']} // Adjust column layout for different screen sizes
          templateRows="auto auto"
          gap={4}
          color='white' as='b' fontSize={['md', 'xl']}
        >
          {/* First Column */}
          <Flex flexDirection="column">
            {/* First Row */}
            <Flex ml='3' justifyContent='space-between'>
              <Box>Total Tickets:</Box>
              <Box>{totalSystemTickets + userTickets.length}</Box>
            </Flex>
            {/* Second Row */}
            <Flex ml='3' justifyContent='space-between'>
              <Box>Price:</Box>
              <Box>${2 * (totalSystemTickets + userTickets.length)}</Box>
            </Flex>
            {showDivider && <Divider mt='2' />}
          </Flex>

          {/* Second Column */}
          <Center gridColumn={['1', '2']} gridRow={['2', 'auto']} ml={['0', 'auto']} mr={['0', '3']}>
            <Button
              py='1.5rem'
              px='3rem'
              m={['0.5%', '1%']}
              bg='#beef00'
              color='black'
              fontSize={['lg', 'xl']}
              onClick={() => {
                auth.user ? checkIsTicketsAvailable() : handleRemoveError("Login or sign up to buy tickets.")
              }}
            >
              Continue
            </Button>
          </Center>
        </Grid>
      </Box>
      <ToastContainer />
    </div>
  )
}

export default BuyTicket1;