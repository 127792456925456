import React from 'react'
import OrderHistory from '../components/OrderHistory';

const OrderHistoryPage = () => {
  return (
    <div>
        <OrderHistory />
    </div>
  )
}

export default OrderHistoryPage