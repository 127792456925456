import React from 'react';
import { Modal, ModalContent, ModalBody, Box, Center, Spinner } from '@chakra-ui/react';

const LoadingModal = ({ isOpen }) => {
  return (
    <Modal isOpen={isOpen} isCentered>
      <ModalContent h='200px' w='200px' bg='#262626' color='white'>
        <ModalBody>
          <Box mt='30%'>
            <Center>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='#beef00' size='xl' />
            </Center>
            <Center fontSize='xl' as='b'>
              Loading
            </Center>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoadingModal;
