import { Tabs, TabList, Tab, TabPanel, TabPanels, Box, SimpleGrid, Flex, Image, Text, Center, Divider } from "@chakra-ui/react";
import { useState, useEffect } from "react"
import axios from "../api/axios";
import { useNavigate } from 'react-router-dom';

import ProfilePicture1 from '../assets/1.png';
import ProfilePicture2 from '../assets/2.png';
import ProfilePicture3 from '../assets/3.png';
import ProfilePicture4 from '../assets/4.png';
import ProfilePicture5 from '../assets/5.png';
import ProfilePicture6 from '../assets/6.png';
import ProfilePicture7 from '../assets/7.png';
import ProfilePicture8 from '../assets/8.png';
import ProfilePicture9 from '../assets/9.png';
import ProfilePicture10 from '../assets/10.png';
import ProfilePicture11 from '../assets/11.png';
import ProfilePicture12 from '../assets/12.png';
import ProfilePicture13 from '../assets/13.png';
import ProfilePicture14 from '../assets/14.png';
import ProfilePicture15 from '../assets/15.png';
import ProfilePicture16 from '../assets/16.png';
import { PrizePercentage } from "../utils/constants/constants";
const SmallPrize = ({ round, totalPrizeMoney }) => {
  const [listOf4thWinners, setListOf4thWinners] = useState([]);
  const [listOf5thWinners, setListOf5thWinners] = useState([]);
  const [listOf6thWinners, setListOf6thWinners] = useState([]);
  const profilePictures = [ProfilePicture1, ProfilePicture2, ProfilePicture3, ProfilePicture4, ProfilePicture5, ProfilePicture6, ProfilePicture7, ProfilePicture8, ProfilePicture9, ProfilePicture10, ProfilePicture11, ProfilePicture12, ProfilePicture13, ProfilePicture14, ProfilePicture15, ProfilePicture16];


  useEffect(() => {
    const winningTicketIds = [4, 5, 6];
    let currentRound = round;
    const fetchWinnersData = async () => {
      const promises = winningTicketIds.map((ticketId) =>
        axios.get(`/winningtickets/${ticketId}/${currentRound}`),
      );
      // console.log(promises);
      try {
        const responses = await Promise.all(promises);
        for (let i = 0; i < responses.length; i++) {
          const ticketData = responses[i].data;
          const userIds = [...new Set(ticketData.map((item) => item.userId))];

          const userPromises = userIds.map((id) =>
            axios.get(`/users/byid/${id}`)
          );

          const userResponses = await Promise.all(userPromises);
          const userData = userResponses.map((userResponse) => userResponse.data);

          const enrichedData = ticketData.map((item) => {
            const user = userData.find((userItem) => userItem.userId === item.userId);
            return {
              ...item,
              userName: user?.userName || '',
              picturePath: user?.picturePath || '',
              randomizedProfilePicture: user?.randomizedProfilePicture,
            };
          });

          if (winningTicketIds[i] === 4) {
            setListOf4thWinners(enrichedData);
          } else if (winningTicketIds[i] === 5) {
            setListOf5thWinners(enrichedData);
          } else if (winningTicketIds[i] === 6) {
            setListOf6thWinners(enrichedData);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchWinnersData();
  }, [round]);

  return (
    <Box p={4} borderWidth={1} borderRadius="md" boxShadow="md">
      <Tabs isFitted variant="enclosed" color='white'>
        <TabList mb="1em">
          <Tab>${(totalPrizeMoney * PrizePercentage.fourthPrize).toFixed(2)} Winners</Tab>
          <Tab>${(totalPrizeMoney * PrizePercentage.fifthPrize).toFixed(2)} Winners</Tab>
          <Tab>${(totalPrizeMoney * PrizePercentage.sixthPrize).toFixed(2)} Winners</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box maxH="300px" overflowY="scroll">
              <SimpleGrid columns={[1, 2, 4]} spacing={4}>
                {listOf4thWinners.map((value, key) => {
                  let userId = value.userId;
                  let userName = value.userName;
                  let profilePic = value.picturePath === '' ? profilePictures[value.randomizedProfilePicture - 1] : value.picturePath;
                  let ticketNumber = value.ticketId;
                  return (
                    <Card
                      key={key}
                      imageUrl={profilePic}
                      userName={userName}
                      ticketNumber={ticketNumber}
                      userId={userId}
                    />
                  );
                })}
              </SimpleGrid>
            </Box>
          </TabPanel>
          <TabPanel>
            <Box maxH="300px" overflowY="scroll">
              <SimpleGrid columns={[1, 2, 4]} spacing={4}>
                {listOf5thWinners.map((value, key) => {
                  let userId = value.userId;
                  let userName = value.userName;
                  let profilePic = value.picturePath === '' ? profilePictures[value.randomizedProfilePicture - 1] : value.picturePath;
                  let ticketNumber = value.ticketId;
                  return (
                    <Card
                      key={key}
                      imageUrl={profilePic}
                      userName={userName}
                      ticketNumber={ticketNumber}
                      userId={userId}
                    />
                  );
                })}
              </SimpleGrid>
            </Box>
          </TabPanel>
          <TabPanel>
            <Box maxH="300px" overflowY="scroll">
              <SimpleGrid columns={[1, 2, 4]} spacing={4}>
                {listOf6thWinners.map((value, key) => {
                  let userId = value.userId;
                  let userName = value.userName;
                  let profilePic = value.picturePath === '' ? profilePictures[value.randomizedProfilePicture - 1] : value.picturePath;
                  let ticketNumber = value.ticketId;
                  return (
                    <Card
                      key={key}
                      imageUrl={profilePic}
                      userName={userName}
                      ticketNumber={ticketNumber}
                      userId={userId}
                    />
                  );
                })}
              </SimpleGrid>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
const Card = ({ imageUrl, userName, ticketNumber, userId }) => {
  let navigate = useNavigate();
  return (
    <Box
      maxW="md"
      borderWidth="1px"
      borderRadius="md"
      overflow="hidden"
      boxShadow="md"
      bg="#1A202C"
      color='white'
      borderColor="#beef00"
      onClick={() => { navigate(`/otherprofile/${userId}`) }} cursor="pointer"
    >
      <Flex alignItems="center" p="2">
        <Image borderRadius='full' src={imageUrl} alt={userName} w="25%" />
        <Text ml="4" fontSize="lg" fontWeight="bold">
          {userName}
        </Text>
      </Flex>
      <Divider />
      <Box p="2" >
        <Center>
          <Text as='b' size='xl'>{ticketNumber}</Text>
        </Center>
      </Box>
    </Box>
  );
};



export default SmallPrize;