import React from 'react'
import ChangeProfilePicture from '../components/ChangeProfilePicture'

const ChangeProfilePicturePage = () => {
  return (
    <div>
        <ChangeProfilePicture />
    </div>
  )
}

export default ChangeProfilePicturePage;