import React from 'react'
import ContactUs from '../components/ContactUs';
const ContactUsPage = () => {
  return (
    <div>
        <ContactUs />
    </div>
  )
}

export default ContactUsPage