import React from 'react';
import './HowToBuyTicket.css'
import Carousel from './carousel/Carousel';

function HowToBuyTicket() {
  return (
    <div>
      <Carousel />
    </div>

  );
}

export default HowToBuyTicket
