import { Box, Heading, Text } from "@chakra-ui/react";
import { WebsiteInfo } from "../components/TextFile";

const UserAgreement = () => {
  return (
    <div>
        <Box maxWidth="800px" mx="auto" my={8} color='white'>
            <Heading as="h1" mb={4}>
                {WebsiteInfo.name} User Agreement
            </Heading>
            <Text mb={4}>
                Effective date: January 4th, 2024
            </Text>
            <Text>
                Welcome to our website! Your use of our site constitutes your agreement to abide by the following terms and conditions.
            </Text>
            <Text mt={8} fontWeight="bold">
            By accessing or using our site, you agree not to:
            </Text>
            <Text mt='4' mx='1rem'>
          Systematically retrieve data or content from our Site to create a collection or database without our express written permission.
        </Text>
        <Text mt='4' mx='1rem'>
          Trick, defraud, or mislead us or other users, especially in any attempt to learn sensitive account information such as user passwords.
          </Text>
          <Text mt='4' mx='1rem'>
          Circumvent, disable, or otherwise interfere with security-related features of our Site, including features that prevent or restrict content usage limits.
          </Text>
          <Text mt='4' mx='1rem'>
        Framing or linking to your Site without authorization.
        </Text>
        <Text mt='4' mx='1rem'>
        Use our site in an effort to compete with you, or otherwise use our Site and/or the content Content for any revenue-generating endeavor or commercial enterprise.
        </Text>
        <Text mt='4' mx='1rem'>
        Use our site in an unauthorized way (collecting usernames, email addresses, of users for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses).
        </Text>
        <Text mt='4' mx='1rem'>
        Use any automated system (spiders, robots, cheat utility, scraper, offline reader) to access the site, or launch any unauthorized script or software (Unless as a result of a standard search engine or internet browser usage).
        </Text>
        <Text mt='4' mx='1rem'>
        Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or using or launching any unauthorized script or other software
        </Text>
        <Text mt='4' mx='1rem'>
        Decipher, decompile, disassemble, or reverse engineer any of the software that makes up our Site.
        </Text>
        <Text mt='4' mx='1rem'>
        Copy/adapt our Site's software (includes Flash, PHP, HTML, JavaScript, or other code).
        </Text>
        <Text mt='4' mx='1rem'>
        Bypass measures of your Site designed to prevent/restrict access.
        </Text>
        <Text mt='4' mx='1rem'>
        Interfere, disrupt, or create an undue burden on your Site, networks, or services.
        </Text>
        <Text mt='4' mx='1rem'>
        Upload any materials that actively or passively collects information or acts as a transmission mechanism (includes gifs, 1x1 pixels, web bugs, cookies, spyware, pcms, etc.)
        </Text>
        <Text mt='4' mx='1rem'>
        Impersonate another user or person or use the username of another user.
        </Text>
        <Text mt='4' mx='1rem'>
        Delete the copyright or other proprietary rights notice from any content.
        </Text>
        <Text mt='4' mx='1rem'>
        Use scripts, data-mining, robots, or similar data gathering tools to send comments or messages.
        </Text>
        <Text mt='4' mx='1rem'>
        Attempt to upload or transmit viruses, Trojan horses, or other materials (including excessive use of capital letters and spamming) that interferes with any party's uninterrupted use of our Site and its features.
        </Text>
        <Text mt='4' mx='1rem'>
        Use our site inconsistently with any applicable laws or regulations.
        </Text>
        <Text mt='4' mx='1rem'>
        Use the support services improperly, or submit false reports of abuse or misconduct.
        </Text>
        <Text mt='4' mx='1rem'>
        Harass, abuse, or harm another person using any information obtained from our site.
        </Text>
        <Text mt='4' mx='1rem'>
          Use our site in an effort to compete with us or otherwise utilize the content for any revenue-generating endeavor or commercial enterprise.
        </Text>
        <Text mt='2rem' as='b' display='block'>
        Please review our <a href="/privacy-policy">Privacy Policy</a> to understand how we collect, use, and disclose information.
      </Text>
      <Text mt='1rem' as='b'>
        If you have any questions or concerns regarding this User Agreement, please contact us at {WebsiteInfo.email}
      </Text>
      </Box>
    </div>
  )
}

export default UserAgreement