import React from 'react';
import SignUpForm from '../components/SignUpForm';

const SignUp = () => {
  return (
      <SignUpForm />
  );
};

export default SignUp;
