import React, { useState } from 'react';
import {
  Box, Flex, Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, Button, Modal
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const RenderNotificaton = ({ text, date, notiId }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [ newNotifications, setNewNotifications] = React.useState(auth.user.notifications);
  const stringNotiId = (notiId + "").length === 1 ? "00" + notiId : "0"+notiId;

  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const styles = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    webkitBoxOrient: 'vertical',
    webkitLineClamp: '2',
  }
  const boxStyle = {
    backgroundColor: isHovering ? '#beef00' : '',
  }
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const markAsRead = (stringNotiId) => {
    const zeroOrNot = newNotifications.indexOf(stringNotiId);
    const userId = auth.user.userId;
    if(newNotifications.charAt(zeroOrNot)==='0'){
      const replaceWithOne = newNotifications.replace(stringNotiId, ("1"+stringNotiId.substring(1)));
      setNewNotifications(replaceWithOne);
      setIsOpen(true);
      setAuth(prevAuth => ({
        ...prevAuth,
        user: {
          ...prevAuth.user,
          notifications: replaceWithOne,
        }
      }));
      axiosPrivate.post('/users/updateuser', {userId, notifications: replaceWithOne})
      .then((response)=>{
        if(response.data.success){
          console.log('success');
        }
      })
    }else{
      setIsOpen(true);
    }
  }

  const shouldDisplayIcon = (stringNotiId) => {
    if (newNotifications) {
      const zeroOrNot = newNotifications.indexOf(stringNotiId);
      let isRead = false;
      if(newNotifications.charAt(zeroOrNot)==='0'){
        isRead = true;
      }else{
        isRead = false;
      }
      return isRead;
    }
    return true;
  };
  
  return (
    <>
      <Box
        bg='#262626'
        color='white'
        boxShadow='lg'
        p={2}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        mb='2'
        style={boxStyle}
        borderRadius='lg'
        cursor='pointer'
        onClick={()=>markAsRead(stringNotiId)}
      >
        <Flex>
        {shouldDisplayIcon(stringNotiId) && (
            <Icon
              viewBox='0 0 200 200'
              color='blue.500'
              mr='1'
              mt={['', '3']}
              boxSize={3}
            >
              <path
                fill='currentColor'
                d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
              />
            </Icon>
          )}
          <Box>
            <Text style={styles} mt={['', '1']}>
              {text}
            </Text>
            <Text color='gray.600'>{date}</Text>
          </Box>
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="md" isCentered>
        <ModalOverlay />
        <ModalContent  bg="#434242" color='white' maxH="50vh" w={["80%", "100%"]} >
          <ModalHeader>Notification</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="50vh" overflowY="scroll">
            <Box mb={4}>
              <Text>
                {text}
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button bg='#beef00' color='black' onClick={() => { setIsOpen(false) }}>OK</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RenderNotificaton;