import { useState } from 'react';
import { Center, Text, Divider, Box, Flex, Collapse, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Input } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FAQ = () => {
  const [HTBTCollapse, setHTBTCollapse] = useState(true);
  const [HMTCIBCollapse, setHMTCIBCollapse] = useState(false);
  const [HWTRCCollapse, setHWTRCCollapse] = useState(false);
  const [WPMADCollapse, setWPMADCollapse] = useState(false);
  const [HTGRCollapse, setHTGRCollapse] = useState(false);
  const [WHWYWCollapse, setWHWYWCollapse] = useState(false);

  return (
    <>
      <Center fontSize={['30px','35px']} as='b' color='white' mb='3'>
        FAQ
      </Center>
      <Divider />
      <Box>
        <Box m='1rem'>
          <Flex justifyContent='space-between' onClick={() => setHTBTCollapse(!HTBTCollapse)} cursor='pointer'>
            <Text as='b' fontSize={['lg', 'xl']} color='white' p='3'>How to Generate Tickets?</Text>
            {!HTBTCollapse ? <ChevronDownIcon mr='4' w={8} h={6} color='white' alignSelf='center' />
              : <ChevronUpIcon mr='4' w={8} h={6} color='white' alignSelf='center' />}
          </Flex>
          <Collapse in={HTBTCollapse} animateOpacity>
            {HTBT()}
          </Collapse>
          {!HTBTCollapse && <Divider />}
        </Box>
        <Box m='1rem'>
          <Flex justifyContent='space-between' onClick={() => setHMTCIBCollapse(!HMTCIBCollapse)} cursor='pointer'>
            <Text as='b' fontSize={['lg', 'xl']} color='white' p='3'>How Many Tickets can I Purchase?</Text>
            {!HMTCIBCollapse ? <ChevronDownIcon mr='4' w={8} h={6} color='white' alignSelf='center' />
              : <ChevronUpIcon mr='4' w={8} h={6} color='white' alignSelf='center' />}
          </Flex>
          <Collapse in={HMTCIBCollapse} animateOpacity>
            {HMTCIB()}
          </Collapse>
          {!HMTCIBCollapse && <Divider />}
        </Box>
        <Box m='1rem'>
          <Flex justifyContent='space-between' onClick={() => setHWTRCCollapse(!HWTRCCollapse)} cursor='pointer'>
            <Text as='b' fontSize={['lg', 'xl']} color='white' p='3'>How Winning Tickets are Chosen?</Text>
            {!HWTRCCollapse ? <ChevronDownIcon mr='4' w={8} h={6} color='white' alignSelf='center' />
              : <ChevronUpIcon mr='4' w={8} h={6} color='white' alignSelf='center' />}
          </Flex>
          <Collapse in={HWTRCCollapse} animateOpacity>
            {HWTRC()}
          </Collapse>
          {!HWTRCCollapse && <Divider />}
        </Box>
        <Box m='1rem'>
          <Flex justifyContent='space-between' onClick={() => setWPMADCollapse(!WPMADCollapse)} cursor='pointer'>
            <Text as='b' fontSize={['lg', 'xl']} color='white' p='3'>Why are Prize Money Different each Round? </Text>
            {!WPMADCollapse ? <ChevronDownIcon mr='4' w={8} h={6} color='white' alignSelf='center' />
              : <ChevronUpIcon mr='4' w={8} h={6} color='white' alignSelf='center' />}
          </Flex>
          <Collapse in={WPMADCollapse} animateOpacity>
            {WPMAD()}
          </Collapse>
          {!WPMADCollapse && <Divider />}
        </Box>
        <Box m='1rem'>
          <Flex justifyContent='space-between' onClick={() => setHTGRCollapse(!HTGRCollapse)} cursor='pointer'>
            <Text as='b' fontSize={['lg', 'xl']} color='white' p='3'>How to get Refund? </Text>
            {!HTGRCollapse ? <ChevronDownIcon mr='4' w={8} h={6} color='white' alignSelf='center' />
              : <ChevronUpIcon mr='4' w={8} h={6} color='white' alignSelf='center' />}
          </Flex>
          <Collapse in={HTGRCollapse} animateOpacity>
            {HTGR()}
          </Collapse>
          {!HTGRCollapse && <Divider />}
        </Box>
        <Box m='1rem'>
          <Flex justifyContent='space-between' onClick={() => setWHWYWCollapse(!WHWYWCollapse)} cursor='pointer'>
            <Text as='b' fontSize={['lg', 'xl']} color='white' p='3'>What Happen when your Ticket(s) Won?  </Text>
            {!WHWYWCollapse ? <ChevronDownIcon mr='4' w={8} h={6} color='white' alignSelf='center' />
              : <ChevronUpIcon mr='4' w={8} h={6} color='white' alignSelf='center' />}
          </Flex>
          <Collapse in={WHWYWCollapse} animateOpacity>
            {WHWYW()}
          </Collapse>
          {!WHWYWCollapse && <Divider />}
        </Box>
      </Box>
      <ToastContainer />
    </>
  );
};



const HTBT = () => {
  return (
    <Box p='3' bg='gray.600' borderRadius='md' mt='2' color='white'>
      <Text color='white' mt='2'>
        You can buy tickets on our website using the following methods:
      </Text>
      <Box pl='4' mt='2'>
        <Text>
          1. Generate Your Own Tickets:
        </Text>
        <Text ml='4'>
          - Input 8 characters (4 letters + 4 numbers) to create a specific ticket.
        </Text>
        <Text ml='4'>
          - Each unique combination of characters corresponds to a unique ticket.
        </Text>
        <Text>
          2. System Generated Tickets:
        </Text>
        <Text ml='4'>
          - Input 0 to 5 characters to have the system generate tickets for you.
        </Text>
        <Text ml='4'>
          - System generates tickets using both random and range methods.
        </Text>
        <Text ml='4'>
          - If you enter 6 characters, system generates the remaining 100 tickets (From XXXXXX00 to XXXXXX99).
        </Text>
        <Text>
          Note:
        </Text>
        <Text ml='4'>
          - You cannot input 7 characters.
        </Text>
      </Box>
    </Box>
  );
};

const HMTCIB = () => {
  return (
    <Box p='3' bg='gray.600' borderRadius='md' mt='2' color='white'>
      <Text>You will see there will be two boxes on Buy Tickets page.</Text>
      <Text>You can generate up to 1000 tickets in each box, which means you can generate and purchase 2000 tickets each time.</Text>
      <Text>Please remember that each user can purchase up to 2000 tickets in one round, but not more than that.</Text>
    </Box>
  )
}

const HWTRC = () => {
  return (
    <Box p='3' bg='gray.600' borderRadius='md' mt='2' color='white'>
      <Box pl='4' mt='2'>
        <Text>
          On winner announcement day, our system randomizes winning tickets from all the tickets from all users.
        </Text>
      </Box>
    </Box>
  );
};

const WPMAD = () => {
  return (
    <Box p='3' bg='gray.600' borderRadius='md' mt='2' color='white'>
      <Box pl='4' mt='2'>
        <Text>
          We award prize money based on our sale each round and the number of tickets that users buy each round is different. If users buy more tickets in the current round, then the prize money will be increased and vice versa.
        </Text>
      </Box>
    </Box>
  );
};

const HTGR = () => {
  const [orderId, setOrderId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const handleChange = (e) => {
    setOrderId(e.target.value);
  };
  const handleOrderRefund = () => {
    const [userId, purchaseDateStr] = orderId.split('|');
    const purchaseDate = new Date(purchaseDateStr);
    const currentTime = new Date();
    const timeDifference = currentTime - purchaseDate;
    const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
    try {
      axiosPrivate.get(`/orders/checkrefund/${orderId}`)
        .then((response) => {
          if (response.data.success) {
            if (timeDifference <= 24 * 60 * 60 * 1000) {
              setIsModalOpen(true);
            } else if (timeDifference > 30 * 24 * 60 * 60 * 1000) {
              setIsModalOpen(true);
            } else {
              let waitingDay;
              if (daysDifference < 1) {
                toast.error("You can get a refund after 1 day.")
              } else {
                waitingDay = 30 - daysDifference;
                toast.error(`You have to wait ${waitingDay} days to get a refund.`);
              }
            }
          }
        })
        .catch((error) => {
          toast.error("Order does not exist.");
        })
    } catch (error) {
      toast.error(error);
    }
  }
  const handleSubmit = async () => {
    let [userId, purchaseDate] = orderId.split("|");
    try{
      const response = await axiosPrivate.post('/refunds/newrefund', {
        orderId: orderId, // Replace with the actual order ID
        requestDate: new Date().toISOString(), // Get the current date and time in ISO format
        userId: userId,
        purchaseDate: purchaseDate
      });
  
      // Handle the response here
      if (response.data.success) {
        setOrderId('');
        setIsModalOpen(false);
        toast.success("Refund request submitted successfully");
      } else {
        setOrderId('');
        setIsModalOpen(false);
        toast.error("An error occurred while submitting refund request");
      }
    } catch (error) {
      setIsModalOpen(false);
      toast.error(error.response.data.error);
      // Handle the error here
    }
  }
  return (
    <>
    <Modal isOpen={isModalOpen} isCentered>
      <ModalOverlay />
      <ModalContent style={{ backgroundColor: '#1A202C', color: 'white', width: '95%' }}>
        <ModalHeader>Do you want to get a refund for this order?</ModalHeader>
        <ModalBody>
          <Text>We will transfer your money for this order within 3 days. Please make sure that you set up your wallet address to recieve your refund.</Text>
        </ModalBody>

        <ModalFooter color='black'>
          <Button mr={3} onClick={handleSubmit} bg='red' color='white'>
            Get refund
          </Button>
          <Button bg="#F0EEED" onClick={()=>{setIsModalOpen(false)}}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    <Box p='3' bg='gray.600' borderRadius='md' mt='2' color='white'>
      <Text color='white' mt='2'>
        We need to set the following rules to avoid customers playing with our order system. You can receive a refund if your order:
      </Text>
      <Box pl='4' mt='2'>
        <Text>
          1. Was placed within the last 24 hours.
        </Text>
        <Text>
          2. Was placed more than 30 days ago.
        </Text>
        <Text>
          3. Is from the current round.
        </Text>
      </Box>
      <Text color='white' mt='2'>
        You can check whether or not your order is valid to receive a refund by entering order ID in the following input. To get your order ID, go to Order History and Copy Order ID from the order that you want to get refund.
      </Text>
      <Text color='white' mt='2'>
        Note: Please remember that transaction fee will be deducted from your refund although we give back 100% of your refund.
      </Text>
      {/* <Center mt='4'> */}
        <Box mt='4' mx='1rem'>
          <Box borderRadius='md' mb='4'>
            <Input type='text' placeholder='Enter your order ID' value={orderId} onChange={handleChange} />
            {/* <input type='text' placeholder='Enter your order ID' style={{ width: '150%', borderRadius: '10px', padding: '5px', color: 'black' }} value={orderId} onChange={handleChange} /> */}
          </Box>
          <Center>
            <Button bg="#beef00" w="40%" color="black" px="4" onClick={handleOrderRefund}>
              Check
            </Button>
          </Center>
        </Box>
      {/* </Center> */}
    </Box>
    </>
  );
};

const WHWYW = () => {
  return (
    <Box p='3' bg='gray.600' borderRadius='md' mt='2' color='white'>
      <Box pl='4' mt='2'>
        <Text>
          In the event that one of your tickets is among the winning entries, you will receive your prize money after 24 hours of winners announcement.
        </Text>
        <Box mt='3' as='b'>
          Why the 24-hour delay?
        </Box>
        <Text>
          - This time frame allows us to ensure that all winners have successfully configured their wallet addresses before funds are transferred. A notification is sent to all winners to check their wallet addresses, and all winners are required to check their wallet addresses and its network within the 24-hour window.
        </Text>
        <Text>
          - If user's wallet network and wallet address are not empty, we will transfer the prize money not later than 3 days, excluding 24-hour checking delay.
        </Text>
        <Text>
          - If you encounter any challenges while setting up your wallet address, you can set your network or wallet address empty in the setting. We will postpone the transaction to next round of winner announcement if we notice that a user network or wallet address is empty.
        </Text>
        <Text>
          - It's important to note that if your network or wallet address is empty, the transfer will take place during the subsequent winner announcement.
        </Text>
        <Text>
          - Please be aware that we cannot be held responsible for refunding prize money in cases where users provide fake or incorrect wallet addresses after the transaction has been completed.
        </Text>
      </Box>
    </Box>
  );
};


export default FAQ;
