import React from 'react';
import { Box, Divider } from "@chakra-ui/react";
import HowToBuyTicket from '../components/HowToBuyTiicket';
import LastMonthWinner from '../components/LastMonthWinner';
import FAQ from '../components/FAQ';
import FirstRound from './FirstRound';

const Home = () => {
  const haveWinner = false;

  return (
    <>
      <Divider />
      <Box my='3'>
        <FirstRound />
      </Box>
      <Divider />
      {haveWinner &&
        <>
          <Box my='3'>
            <LastMonthWinner />
          </Box>
          <Divider />
        </>
      }
      <Box my='3'>
        <HowToBuyTicket />
      </Box>
      <Divider />
      <Box my='3'>
        <FAQ />
      </Box>
    </>
  );
};

export default Home;