import { useEffect, useState, useRef } from 'react';
import RenderNotificaton from '../components/RenderNotification';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';
import { Box, Center, Divider } from '@chakra-ui/react';

//this notification is for small screen
const Notification = () => {
  const effectRan = useRef(false);
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [notiList, setNotiList] = useState([]);
    useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
      if (effectRan.current === true) {
          const getNotifications = async () => {
              try {
                  const response = await axiosPrivate.get(`/notifications/byid/${auth.user.userId}`, {
                      signal: controller.signal
                  });
                  isMounted && setNotiList(response.data);
              } catch (err) {
                  console.log(err);
                  navigate('/login', { state: { from: location }, replace: true });
              }
          }
          getNotifications();

      }
      return () => {
          isMounted = false;
          controller.abort();
          effectRan.current = true;
      }
  }, []);
  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }
  
  return (
    <div>
        <Center fontSize={['30px', '35px']} as='b' color='white' mb='1'>Notifications</Center>
        <Divider mb='2'/>
      {notiList.length === 0 ?
      <Box mt='40vh'>
        <Center as='b' color='white' fontSize='lg'>You do not have any notification.</Center>
      </Box>
      :
      //for small screen
      [...notiList].reverse().map((content, index) => (
        <RenderNotificaton key={index} text={content.text} date={formatDate(content.date)} notiId={content.id} />
      ))
    }
    </div>
  )
}

export default Notification;
// import { useEffect, useState, useRef } from 'react';
// import RenderNotificaton from '../components/RenderNotification';
// import { useNavigate, useLocation } from 'react-router-dom';
// import useAxiosPrivate from '../hooks/useAxiosPrivate';
// import useAuth from '../hooks/useAuth';
// import { Box, Center, Divider } from '@chakra-ui/react';

// //this notification is for small screen
// const Notification = () => {
//   const effectRan = useRef(false);
//     const axiosPrivate = useAxiosPrivate();
//     const { auth } = useAuth();
//     const navigate = useNavigate();
//     const location = useLocation();
//     const [notiList, setNotiList] = useState([]);
//     useEffect(() => {
//       let isMounted = true;
//       const controller = new AbortController();
//       if (effectRan.current === true) {
//           const getNotifications = async () => {
//               try {
//                   const response = await axiosPrivate.get(`/notifications/byid/${auth.user.userId}`, {
//                       signal: controller.signal
//                   });
//                   console.log(response.data);
//                   isMounted && setNotiList(response.data);
//               } catch (err) {
//                   console.log(err);
//                   // navigate('/login', { state: { from: location }, replace: true });
//               }
//           }
//           getNotifications();

//       }
//       return () => {
//           isMounted = false;
//           controller.abort();
//           effectRan.current = true;
//       }
//   },[]);
//   function formatDate(dateString) {
//     const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
//     const date = new Date(dateString);
//     return date.toLocaleDateString(undefined, options);
//   }
  
//   return (
//     <div>
//         <Center fontSize={['30px', '35px']} as='b' color='white' mb='1'>Notifications</Center>
//         <Divider mb='2'/>
//       {notiList.length === 0 ?
//       <Box mt='40vh'>
//         <Center as='b' color='white' fontSize='lg'>You do not have any notification.</Center>
//       </Box>
//       :
//       //for small screen
//       [...notiList].reverse().map((content, index) => (
//         <RenderNotificaton key={index} text={content.text} date={formatDate(content.date)} notiId={content.id} />
//       ))
//     }
//     </div>
//   )
// }

// export default Notification;