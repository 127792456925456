import React from 'react'
import Setting from '../components/Setting';

const SettingPage = () => {
  return (
    <div>
        <Setting />
    </div>
  )
}

export default SettingPage